import axios from 'axios'
import { isAuthTokenExists, getAuthToken } from '../helpers';

const token = isAuthTokenExists() ? getAuthToken() : '';

export default axios.create({
  // https://play2wins.xyz/admin
  // http://127.0.0.1:8000/admin
  // http://64.227.164.243/admin
  baseURL: "http://64.227.164.243/admin",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  },
});
<template>
  <nav
    id="sidebarMenu"
    class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse p-0"
  >
    <div class="sidebar-sticky sidebar_panel">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'dashboard' ? 'active' : ''"
            to="/"
            >Dashboard</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'users' ? 'active' : ''"
            to="/users"
            >Users</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'results' ? 'active' : ''"
            to="/results"
            >Results</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'deposit-requests' ? 'active' : ''"
            to="/deposit-requests"
          >
            Add Money Requests
            <span v-if="depositRequestsCount > 0" class="badge badge-warning">{{
              depositRequestsCount
            }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'deposit-history' ? 'active' : ''"
            to="/deposits"
            >Add Money History</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'support-tickets' ? 'active' : ''"
            to="/support-tickets"
          >
            Support Tickets
            <span v-if="supportTicketsCount > 0" class="badge badge-warning">{{
              supportTicketsCount
            }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'bets' ? 'active' : ''"
            to="/bets"
            >Bet Amounts</router-link
          >
        </li>
        <h6
          class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1"
        >
          <span class="text-muted">Pending Withdrawals</span>
        </h6>
        <ul class="nav flex-column mb-2">
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="withdrawalRequestType === 'BANK' ? 'active' : ''"
              to="/withdrawal-requests/bank"
            >
              Bank
              <span
                v-if="bankWithdrawalRequestsCount > 0"
                class="badge badge-warning"
                >{{ bankWithdrawalRequestsCount }}</span
              >
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="withdrawalRequestType === 'PAYTM' ? 'active' : ''"
              to="/withdrawal-requests/paytm"
            >
              Paytm
              <span
                v-if="paytmWithdrawalRequestsCount > 0"
                class="badge badge-warning"
                >{{ paytmWithdrawalRequestsCount }}</span
              >
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="withdrawalRequestType === 'UPI' ? 'active' : ''"
              to="/withdrawal-requests/upi"
            >
              Upi
              <span
                v-if="upiWithdrawalRequestsCount > 0"
                class="badge badge-warning"
                >{{ upiWithdrawalRequestsCount }}</span
              >
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" target="_blank" :href="allDownloadLink">
                            All
                        </a>
            <!-- <router-link
              class="nav-link"
              :href="allDownloadLink"
            >
              All
            </router-link> -->
          </li>
        </ul>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'jantri' ? 'active' : ''"
            to="/jantri"
            >Jantri</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'banners' ? 'active' : ''"
            to="/banners"
            >Banners</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'games' ? 'active' : ''"
            to="/games"
            >Games</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'links' ? 'active' : ''"
            to="/links"
            >Links</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'withdrawal-history' ? 'active' : ''"
            to="/withdrawals"
            >Withdrawal History</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'winners' ? 'active' : ''"
            to="/winners"
            >Winners</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'push-notification' ? 'active' : ''"
            to="/push-notification"
            >Push Notification</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'settings' ? 'active' : ''"
            to="/settings"
            >Settings</router-link
          >
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            :class="routeName === 'admins' ? 'active' : ''"
            to="/admins"
            >Admins</router-link
          >
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      routeName: this.$route.name,
      withdrawalRequestType: "",
    };
  },
  computed: {
    ...mapGetters({
      supportTicketsCount: "admin/getSupportTicketsCount",
      depositRequestsCount: "admin/getDepositRequestsCount",
      bankWithdrawalRequestsCount: "admin/getBankWithdrawalRequestsCount",
      paytmWithdrawalRequestsCount: "admin/getPaytmWithdrawalRequestsCount",
      upiWithdrawalRequestsCount: "admin/getUpiWithdrawalRequestsCount",
    }),
    allDownloadLink(){
      return this.$baseUrl + "admin/download?type=" + 'all' + "&file=" + 'xlsx';
    }
  },
  watch: {
    "$route.params": {
      handler(newValue) {
        if (this.routeName === "withdrawal-requests") {
          this.withdrawalRequestType = newValue.type.toUpperCase();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  .sidebar_panel {
    padding-top: 3rem !important;
  }
}

@media (max-width: 480px) {
  .sidebar_panel {
    padding-top: 6rem !important;
  }
}
</style>

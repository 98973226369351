<template>
        <Page :loading="isPageLoading">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 w-100 text-center">Deposit Requests</h1>
        </div>

        <div class="container-fluid mt-5">
            <div class="row">
                <div class="col-12">
                    <table class="table table-bordered table-response">
                        <thead>
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">Name</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Wallet Balance</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Number</th>
                                <th scope="col">Screenshot</th>
                                <th scope="col">Requested</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :style="[request.user.highlighted ? {'background': '#fb8888'} : {'background': 'transparent'}]"  v-for="(request, index) in requests" :key="index">
                                <td>#{{ index + 1 }}</td>
                                <td>{{ request.user.name }}</td>
                                <td>{{ request.user.mobile }}</td>
                                <td>{{ request.user.wallet }}</td>
                                <td>{{ request.amount }}</td>
                                <td>{{ request.data.number }}</td>
                                <td v-if="request.data.screenshot !== ''">
                                    <a target="_blank" :href="getScreenshotUrl(request.data.screenshot)">
                                        <img class="screenshot_img" :src="getScreenshotUrl(request.data.screenshot)" alt="">
                                    </a>
                                </td>
                                <td v-else>
                                    Not Given
                                </td>
                                <td>{{ moment(request.createdAt).format('DD/MM/YYYY h:mm A') }}</td>
                                <td>
                                    <button @click="depositAction(request.userId, request._id, true)" class="btn btn-primary btn-sm">Accept</button> |
                                    <button @click="depositAction(request.userId, request._id, false)" class="btn btn-danger btn-sm">Reject</button> 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </Page>

</template>

<script>
    import Page from '../components/Page'
    import DespositService from '../services/DepositService'

    export default {
        name: 'DepositRequest',
        components: {
            Page,
        },
        data(){
            return {
                pageLoading: true,
                requests: [],
            }
        },
        computed:{
            isPageLoading(){
                return this.pageLoading;
            }
        },
        methods: {
            getScreenshotUrl(screenshot){
                return this.$baseUrl + 'requests/' + screenshot;
            },
            getDepositRequests(){
                DespositService.getDepositRequests()
                .then(response => {
                    const { error, return:data } = response.data;
                    if(!error && data){
                        this.requests = data.requests;
                        this.pageLoading = false;
                    }
                });
            },
            depositAction(userId, requestId, accept){
                const status = accept ? 'SUCCESS' : 'REJECTED';

                
                    DespositService.updateDepositRequest({
                    userId: userId,
                    requestId: requestId,
                    status: status,
                })
                .then(response => {
                    const { error, return:data } = response.data;
                    if(!error && data){
                        this.requests.splice(this.requests.findIndex(request => request._id === requestId), 1);
                        accept ? this.flash("Deposit request accepted successfully.", "success", { timeout: 5000 }) : this.flash("Deposit request rejected successfully.", "success", { timeout: 5000 });
                    } 
                })
                
            }
        },
        created(){
            this.getDepositRequests();
        }
    }
</script>

<style scoped>
    .screenshot_img{
        width: 100px;
        height: 100px;
    }
</style>
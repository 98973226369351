<template>
    <div>
      <flash-message class="flash_container"></flash-message>
        <Header />
        <div class="container-fluid">
            <div class="row">
                <SideBar />
                <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-md-4">
                  <Loader v-if="loading" />
                  <slot v-else ></slot>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import Header from './Header'
import SideBar from './SideBar'
    export default {
        props: {
          loading: {
            type: Boolean,
            required: true,
          }
        },
        components: {
          Header,
          SideBar
        }
    }
</script>

<style>

body{
 background:#282f4c !important;
}

h1, h2, h3, h4{
  color: #FFF !important;
}
table thead tr th{
  color: #007bff;
}

table tbody tr td {
  color: #FFF;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
   * Sidebar
   */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 48px 0 0;
  /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

/* @media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
} */

.sidebar-sticky {
  position: relative;
  top: 0;
  height: 100%;
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  background: #121833;
  /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 700;
  color: #FFF;
  font-size: 16px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
   * Navbar
   */


.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}


.sidebar-heading span{
    font-weight: 900;
    color: dodgerblue;
    font-size: 20px;
}

td, td.text-center{
    font-weight: bold;
}
td.text-center a{
    color: #00dd1c;
    font-weight: 700;
    font-size: 15px;
}


.playindia_card {
    background: #121833;
    border-radius: 5px;
}

.playindia_card .card-body h5{
    color: #FFF;
}

.flash_container{
  position: fixed;
  z-index: 999;
  width: 100%;
}
</style>
<template>
  <div id="app" class="h-100">
    <Splash v-if="apiStateLoading" />
    <Error v-if="apiStateError" />
    <router-view v-if="apiStateLoaded || !isAuthenticatedRoute"/>    
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Splash from './views/Splash'
import Error from './views/Error'
import ENUM from './store/enums'

    export default {
      sockets: {
          connect: function () {
              console.log('socket connected')
          },
          adminData: function (data) {
              this.$store.dispatch('admin/setAdminOnlineUsers', data.onlineUsers, { root: true });

              if(data.ticketsCount > this.getSupportTicketsCount || data.depositRequestsCount > this.getDepositRequestsCount){
                        
                const playPromise = new Audio('http://64.227.164.243/sounds/sword.mp3').play();
                if (playPromise !== undefined) {
                  playPromise.then(function() {
                    // Automatic playback started!
                  }).catch(function(error) {
                    // Automatic playback failed.
                    // Show a UI element to let the user manually start playback.
                    console.log('Notification sound failed');
                  });
                }
              }
              this.$store.dispatch('admin/setSupportTicketsCount', data.ticketsCount, { root: true });
              this.$store.dispatch('admin/setDepositRequestsCount', data.depositRequestsCount, { root: true });
              this.$store.dispatch('admin/setBankWithdrawalRequestsCount', data.bankWithdrawalRequestsCount, { root: true });
              this.$store.dispatch('admin/setPaytmWithdrawalRequestsCount', data.paytmWithdrawalRequestsCount, { root: true });
              this.$store.dispatch('admin/setUpiWithdrawalRequestsCount', data.upiWithdrawalRequestsCount, { root: true });
          }
        },
        components: {
            Splash,
            Error
        },
        methods: {
        
        },
        computed: {
          isAuthenticatedRoute(){
            return this.$route.meta.authenticationRequired;
          },
          ...mapState({
            apiState: state => state.api.status.type,
            getSupportTicketsCount: state => state.admin.supportTicketsCount,
            getDepositRequestsCount: state => state.admin.depositRequestsCount,
          }),
          apiStateLoaded() {
            return this.apiState === ENUM.LOADED;
          },
          apiStateLoading() {
            return this.apiState === ENUM.LOADING || this.apiState === ENUM.INIT && this.isAuthenticatedRoute && this.$helpers.isAuthTokenExists();
          },
          apiStateError() {
            return this.apiState === ENUM.ERROR;
          }
        }
    }
</script>

<style >
  html,
body {
    height: 100%;
}

table thead tr th, td{
  text-align: center;
}

</style>

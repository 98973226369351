<template>
  <Page :loading="isPageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">Deposit History</h1>
    </div>

    <div class="container-fluid mt-5">

      <div class="row">
        <div class="col-8 m-auto">
          <div class="form-group">
            <label for="userSeachInput" class="d-block text-white text-center h5"
              >Search Deposits</label
            >
            
            <div class="row">
              <div class="col-10 m-0 p-0">
              <input
              type="text"
              class="form-control"
              @keyup="searchDeposit($event)"
              id="depositSeachInput"
              aria-describedby="helpId"
              placeholder="Search deposits by name, id or mobile..."
            />
            </div>

            <div class="col-2 m-0 p-0">
              <select @change="onSearchFilterChange($event)" class="form-control">
              <option value="MOBILE">By Mobile</option>
              <option value="DATE">By Date</option>
              <option value="TRANSACTION_ID">By Transaction Id</option>
              <option value="ORDER_ID">By Order Id</option>
              <option value="TYPE">By Order Type</option>
            </select>
            </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">

          <table v-if="isSearchingDeposits" class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile</th>
                <th scope="col">Amount</th>
                <th scope="col">By</th>
                <th scope="col">Transaction Id</th>
                <th scope="col">Current Balance</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr  v-for="(deposit, index) in getSearchedDeposits" :key="deposit._id">
                <td>{{ moment(deposit.createdAt).format("DD/MM/YYYY h:mm A") }}</td>
                <td>{{ deposit.user.name }}</td>
                <td>{{ deposit.user.mobile }}</td>
                <td>{{ deposit.amount }}</td>
                <td>{{ getDepositMethod(deposit) }}</td>
                <td>{{ getDepositTransactionId(deposit) }}</td>
                <td>{{ deposit.user.wallet }}</td>
                <td>
                  <button
                    @click="removeDeposit(index)"
                    type="button" 
                    class="btn btn-primary btn-sm"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>


          <div v-if="!isSearchingDeposits" class="table-responsive">
            <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile</th>
                <th scope="col">Amount</th>
                <th scope="col">By</th>
                <th scope="col">Transaction Id</th>
                <th scope="col">Current Balance</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(deposit, index) in getDeposits" :key="deposit._id">
                <td>{{ moment(deposit.createdAt).format("DD/MM/YYYY h:mm A") }}</td>
                <td>{{ deposit.user.name }}</td>
                <td>{{ deposit.user.mobile }}</td>
                <td>{{ deposit.amount }}</td>
                <td>{{ getDepositMethod(deposit) }}</td>
                <td>{{ getDepositTransactionId(deposit) }}</td>
                <td>{{ deposit.user.wallet }}</td>
                <td>
                  <button
                    @click="removeDeposit(index)"
                    type="button"
                    class="btn btn-primary btn-sm"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
          
          <nav v-if="!isSearchingDeposits && totalCount > perPage" aria-label="Page navigation">
            <ul class="pagination flex-wrap float-right">
              <li class="page-item" v-if="page !== 1" @click="page--">
                <a class="page-link" href="#">«</a>
              </li>
                <li
                  class="page-item"
                  :class="{ active: pageNumber === page }"
                  v-for="pageNumber in pagination(page, totalPagesCount)"
                  :key="pageNumber"
                  @click="page = pageNumber"
                >
                  <a class="page-link" href="#">{{ pageNumber }}</a>
                </li>
              <li
                class="page-item"
                v-if="page < totalPagesCount"
                @click="page++"
              >
                <a class="page-link" href="#">»</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page";
import DepositService from "../services/DepositService";
import UserService from "../services/UserService";
export default {
  name: "DepositHistory",
  components: {
    Page,
  },
  data() {
    return {
      pageLoading: true,
      deposits: [],
      page: 1,
      perPage: 10,
      totalCount: 0,
      searchedDeposits: [],
      searchingDeposits: false,
      searchBy: 'MOBILE',
    };
  },
  computed: {
    getDeposits() {
      return this.deposits;
    },
    isPageLoading() {
      return this.pageLoading;
    },
    isSearchingDeposits(){
      return this.searchingDeposits;
    },
    getSearchedDeposits(){
      return this.searchedDeposits.filter(deposit => deposit.user !== null);
    },
    totalPagesCount() {
      return Math.ceil(this.totalCount / this.perPage);
    },
  },
  methods: {
    onSearchFilterChange(event){
      this.searchBy = event.target.value;
    },
    loadDeposits(limit, skip) {
      DepositService.getDepositHistory({
        limit: limit,
        skip: skip,
      }).then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.deposits = data.deposits;
          this.totalCount = data.totalDepositsCount;
          this.pageLoading = false;
        }
      });
    },
    paginateDeposits(newPageNumber, oldPageNumber) {
      let limit = this.perPage,
        skip = this.page * this.perPage - this.perPage;

      this.pageLoading = true;
      this.loadDeposits(limit, skip);
    },
    getDepositMethod(deposit) {
      if (deposit.type === "ADMIN_WALLET_ADD") {
        return "ADMIN";
      }
      else if(deposit.type === "DEPOSIT_REQUEST"){
        return "Deposit Request";
      }
      else if(deposit.type === "PLAYSTORE_WALLET_ADD"){
        return "Deposited by Playstore";
      }
      else if(deposit.type === "ZINNA_WALLET_ADD"){
        return "Deposited by Zinna";
      }
       else {
        let app = '';
        switch (deposit.data.by) {
            case 'COM.GOOGLE.ANDROID.APPS.NBU.PAISA.USER':
                app = 'GooglePay';
                break;
            case 'NET.ONE97.PAYTM':
                app = 'Paytm';
                break;
            case 'COM.PHONEPE.APP':
                app = 'PhonePay';
                break;
            case 'IN.ORG.NPCI.UPIAPP':
                app = 'BHIMUpi';
                break;
            case 'IN.AMAZON.MSHOP.ANDROID.SHOPPING':
                app = 'AmazonPay';
                break;
            case 'RAZORPAY':
                app = 'RazorPay';
                break;
            case 'QRCODE':
                app = 'QR Code';
                break;
            
        } 
        return app;
      }
    },
    getDepositTransactionId(deposit) {
      if (deposit.type === "ADMIN_WALLET_ADD" || deposit.type === "DEPOSIT_REQUEST" || (deposit.type === "PLAYSTORE_WALLET_ADD" && !deposit.data) || (deposit.type === "ZINNA_WALLET_ADD" && !deposit.data)) {
        return "";
      } else {
        return deposit.data.transactionId;
      }
    },
    removeDeposit(depositIndex) {
      if (confirm("Are you sure you want to remove this deposit?")) {
        DepositService.delete({
          transactionId: this.deposits[depositIndex]._id,
        }).then((response) => {
          const { error, return: data } = response.data;
          if (!error && data) {
            this.deposits.splice(depositIndex, 1);
            this.flash("Deposit deleted successfully.", "success", {
              timeout: 5000,
            });
          }
        });
      }
    },
    searchDeposit(event){
      const queryString = event.target.value.trim();
      this.searchingDeposits = queryString !== "" ? true : false;
      if (queryString !== "") {
        UserService.searchDeposits({
          query: queryString,
          filterBy: this.searchBy,
        }).then((response) => {
          const { error, return: data } = response.data;
          if (!error && data !== []) {
            this.searchedDeposits = data.deposits;
          }
        });
      }
    },
    pagination(c, m) {
      var current = c,
        last = m,
        delta = 3,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },
  watch: {
    page(newPageNumber, oldPageNumber) {
      this.paginateDeposits(newPageNumber, oldPageNumber);
    },
  },
  created() {
    this.loadDeposits(this.perPage, 0);
  },
};
</script>

<style scoped></style>
